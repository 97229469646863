var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.newsTitle))]),_c('p',{staticStyle:{"padding":"0","font-size":"14px"}},[_c('v-icon',[_vm._v("mdi-calendar-outline")]),_vm._v(" "+_vm._s(_vm._f("momentFormatM")(_vm.newsCreatedAt))+" ")],1),_c('v-divider'),_c('br'),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"justify","text-justify":"inter-word"}},[(_vm.newsUrl === '' || _vm.newsUrl === undefined)?_c('v-img',{staticClass:"float-left",staticStyle:{"box-shadow":"1px 1px 5px #888888","margin-right":"20px","margin-bottom":"7px"},attrs:{"alt":_vm.newsTitle,"width":_vm.$vuetify.breakpoint.mobile ? '99%' : '49%',"height":_vm.$vuetify.breakpoint.mobile ? '300' : '500',"src":require("../assets/images/no-photo-available.png"),"lazy-src":require("../assets/images/no-image-icon.png")}}):_c('v-img',{staticClass:"float-left",staticStyle:{"box-shadow":"1px 1px 5px #888888","margin-right":"20px","margin-bottom":"10px"},attrs:{"alt":_vm.newsTitle,"src":_vm.newsUrl,"width":_vm.$vuetify.breakpoint.mobile ? '99%' : '49%',"height":_vm.$vuetify.breakpoint.mobile ? '300' : '500'}}),_c('pre',{staticClass:"newsBody"},[_vm._v(_vm._s(_vm.newsBody))])],1)],1),_c('hr'),_c('br'),_c('h2',{staticClass:"text-center mb-10",staticStyle:{"color":"#6c2c73"}},[_vm._v(" "+_vm._s(_vm.$t("latestNews"))+" ")]),_c('v-row',[_vm._l((_vm.fetchedNews),function(n){return _c('v-col',{key:n._id,attrs:{"cols":"12","lg":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"elevation":hover ? 6 : 2},on:{"click":function($event){_vm.goToDetail('newsDetail', {
                  newsId: n._id,
                  newsTitle: n.title,
                  newsBody: n.body,
                  newsCreatedAt: n.createdAt,
                  newsUrl: n.hasOwnProperty('url') ? n.url : undefined,
                })}}},[(n.url === '' || n.url === undefined)?_c('v-img',{staticClass:"align-end",attrs:{"alt":n.title,"height":"250px","src":require("../assets/images/no-photo-available.png")}}):_c('v-img',{staticClass:"align-end",attrs:{"alt":n.title,"height":"250px","src":n.url}}),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm._f("momentFormatM")(n.createdAt))+" ")]),_c('v-card-text',{staticClass:"text--primary"},[_c('h2',[_vm._v(" "+_vm._s(n.title.length > 30 ? n.title.substring(0, 30) + "..." : n.title)+" ")]),_c('div',[_vm._v(_vm._s(n.body.substring(0, 150) + "..."))])])],1)]}}],null,true)})],1)}),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.totalPage / 10),"total-visible":9,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }