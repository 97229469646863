<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col>
        <h1>{{ newsTitle }}</h1>
        <p style="padding: 0; font-size: 14px">
          <v-icon>mdi-calendar-outline</v-icon>
          {{ newsCreatedAt | momentFormatM }}
        </p>
        <v-divider></v-divider>
        <br />
        <v-row>
          <v-col style="text-align: justify; text-justify: inter-word">
            <v-img
              v-if="newsUrl === '' || newsUrl === undefined"
              :alt="newsTitle"
              :width="$vuetify.breakpoint.mobile ? '99%' : '49%'"
              :height="$vuetify.breakpoint.mobile ? '300' : '500'"
              style="
                box-shadow: 1px 1px 5px #888888;
                margin-right: 20px;
                margin-bottom: 7px;
              "
              src="../assets/images/no-photo-available.png"
              lazy-src="../assets/images/no-image-icon.png"
              class="float-left"
            />
            <v-img
              :alt="newsTitle"
              v-else
              :src="newsUrl"
              :width="$vuetify.breakpoint.mobile ? '99%' : '49%'"
              :height="$vuetify.breakpoint.mobile ? '300' : '500'"
              style="
                box-shadow: 1px 1px 5px #888888;
                margin-right: 20px;
                margin-bottom: 10px;
              "
              class="float-left"
            />
            <pre class="newsBody">{{ newsBody }}</pre>
          </v-col>
        </v-row>
        <hr />
        <br />
        <h2 style="color: #6c2c73" class="text-center mb-10">
          {{ $t("latestNews") }}
        </h2>
        <v-row>
          <v-col cols="12" lg="6" v-for="n in fetchedNews" :key="n._id">
            <v-hover v-slot="{ hover }" close-delay="200">
              <v-card
                style="cursor: pointer"
                class="mx-auto"
                :elevation="hover ? 6 : 2"
                @click="
                  goToDetail('newsDetail', {
                    newsId: n._id,
                    newsTitle: n.title,
                    newsBody: n.body,
                    newsCreatedAt: n.createdAt,
                    newsUrl: n.hasOwnProperty('url') ? n.url : undefined,
                  })
                "
              >
                <v-img
                  v-if="n.url === '' || n.url === undefined"
                  :alt="n.title"
                  class="align-end"
                  height="250px"
                  src="../assets/images/no-photo-available.png"
                >
                </v-img>
                <v-img
                  v-else
                  :alt="n.title"
                  class="align-end"
                  height="250px"
                  :src="n.url"
                >
                </v-img>

                <v-card-subtitle class="pb-0">
                  {{ n.createdAt | momentFormatM }}
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <h2>
                    {{
                      n.title.length > 30
                        ? n.title.substring(0, 30) + "..."
                        : n.title
                    }}
                  </h2>

                  <div>{{ n.body.substring(0, 150) + "..." }}</div>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn
                      color="purple"
                      text
                    >
                      Share
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="purple"
                      text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions> -->
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="12" md="12" style="text-align: center">
            <v-pagination
              v-model="page"
              :length="Math.ceil(totalPage / 10)"
              :total-visible="9"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import MainMixin from "@/mixins/mainMixin";
import _ from "lodash";

export default {
  name: "NewsDetails",
  mixins: [MainMixin],
  beforeMount() {
    this.fetchParams();
  },
  mounted() {
    this.$jQuery("body").off();
    window.scrollTo(0, 0, "smooth");
  },
  data() {
    return {
      loading: false,
      page: 1,
      totalPage: 0,
      skip: 0,
      limit: 10,
      isChange: false,
      renderInfoWindow: true,
    };
  },
  beforeRouteUpdate(to, from, next) {
    let vm = this;
    if (to.params.newsId !== from.params.newsId) {
      vm.$forceUpdate();
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    next();
  },
  methods: {
    queryNews: _.debounce(function (skip, limit, countryCode) {
      let vm = this;
      vm.fetchNews({
        search: vm.search || "",
        skip: skip || 0,
        limit: limit || 10,
        sortBy: vm.sortBy || "",
        sortDesc: vm.sortDesc || "",
        countryCode: countryCode || "",
      });
      vm.loading = false;
    }, 500),
    goToDetail(link, params) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        this.$router
          .push({
            name: link,
            params: params,
          })
          .catch(() => {});
      }
      vm.fetchParams();
    },
    fetchParams() {
      let vm = this;
      vm.newsId = vm.$route.params.newsId || "";
      vm.newsTitle = vm.$route.params.newsTitle || "";
      vm.newsCreatedAt = vm.$route.params.newsCreatedAt || "";
      vm.newsUrl = vm.$route.params.newsUrl;
      vm.newsBody = vm.$route.params.newsBody || "";
    },
  },
  watch: {
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 1 * (val - 1);
      vm.limit = 10;
      vm.countryCode = "";
      vm.queryNews(vm.skip, vm.limit, vm.countryCode);
    },
  },
  created() {
    let vm = this;
    vm.queryNews();
    vm.fetchParams();
  },
};
</script>
<style scoped>
.newsBody {
  font-family: "Noto Sans Khmer", Roboto, sans-serif !important;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>